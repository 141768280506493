import { createPopper } from '@popperjs/core'

export const mixFormGeral = {
  methods: {
    withPopper(dropdownList, component, { width }) {
      const oDropdownList = dropdownList
      oDropdownList.style.width = width
      oDropdownList.style.maxHeight = '175px'
      oDropdownList.style.zIndex = '9999'

      const popper = createPopper(component.$refs.toggle, oDropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }],
      })

      return () => popper.destroy()
    },
  },
  filters: {
    nl2br(text) {
      if (text) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
      }

      return ''
    },
    getTxt(value) {
      if ((value !== null) && (value !== undefined) && (value !== '') && ('txt' in value)) {
        return value.txt
      }
      return '-----'
    },
    truncate(text, num) {
      const newText = text.split('').slice(0, num).join('')
      return newText
    },
    lowerBold(value) {
      if (!value) return ''
      return `<b>${value.toLowerCase()}</b>`
    },
  },
}

export const _ = null
