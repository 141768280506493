<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      :visible="true"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="$emit('close-modal')"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Nova visita') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>
        <vue-perfect-scrollbar
          class="b-sidebar-scrollarea-form"
          :settings="configSettingsScroll"
        >
          <b-container
            fluid
            class="p-2"
          >
            <validation-observer
              ref="formNovaVisita"
              #default="{}"
            >
              <b-row
                v-if="changeListing === true && arrayAngariacao.pid == null"
                class="mb-2"
              >
                <b-col md="12">
                  <b-button
                    variant="outline-primary"
                    @click.stop.prevent="openModalAngariacao"
                  >
                    <span class="align-middle">{{ $t('Adicionar angariação') }}</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-if="arrayAngariacao.pid != null"
                class="mb-2"
              >
                <b-col
                  md="12"
                  class="d-flex justify-content-between flex-column"
                >
                  <!-- User Avatar & Action Buttons -->
                  <div class="d-flex justify-content-start">
                    <b-avatar
                      :src="arrayAngariacao.imagem + '?v=' + new Date().getTime()"
                      size="110px"
                      rounded
                    />
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <h5 class="mb-0">
                          {{ arrayAngariacao.pid }}
                        </h5>
                        <span class="card-text"> {{ arrayAngariacao.tipologia }} </span><br>
                        <span class="card-text"> {{ arrayAngariacao.nomeConsultor }} </span>
                      </div>
                      <div class="d-flex flex-wrap">
                        <b-button
                          v-if="changeListing === true"
                          variant="outline-primary"
                          @click.stop.prevent="openModalAngariacao"
                        >
                          Alterar
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="arrayConsultoresTeam.length > 0">
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Tipo') | lowerBold"
                  >
                    <b-form-group
                      :label="$t('Escolha um consultor')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        id="fSW070_flttipo"
                        v-model="form_data.sw070s02"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="arrayConsultoresTeam"
                        label="nome"
                        item-text="nome"
                        item-value="id"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        @input="setListLeads"
                      >
                        <template #option="{ nome }">
                          {{ nome }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>

                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    ref="sw070s09input"
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      :label="$tc('Lead', 1)"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        v-model="form_data.sw070s09"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="listLeadsApp"
                        append-to-body
                        :calculate-position="withPopper"
                        :filterable="false"
                        :placeholder="$t('Digite o nome do Lead que procura')"
                        label="desc"
                        item-text="desc"
                        item-value="id"
                        @search="onSearchLeadsAppSelect"
                        @input="setOptionSelectLead"
                      >
                        <div
                          v-if="listLeadsApp.length > 0"
                          slot="no-options"
                        >
                          {{ $t('Sem resultados') }}
                        </div>
                        <template
                          v-if="listLeadsApp.length === 0 && arrayConsultoresTeam.length === 0"
                          slot="no-options"
                        >
                          {{ $t('Digite o nome') }}
                        </template>
                        <template
                          v-if="listLeadsApp.length === 0 && arrayConsultoresTeam.length > 0"
                          slot="no-options"
                        >
                          {{ $t('Selecione um consultor') }}
                        </template>
                        <template
                          slot="option"
                          slot-scope="option"
                        >
                          <div class="d-flex align-items-center">
                            <b-avatar
                              :src="option.photo"
                              class="mr-50"
                            />
                            <div class="detail">
                              <b-card-text class="mb-0">
                                {{ option.desc }}
                              </b-card-text>
                              <b-card-text
                                v-if="option.email"
                                class="mb-0 text-muted"
                              >
                                <small>{{ option.email }}</small>
                              </b-card-text>
                              <b-card-text
                                v-if="option.phone"
                                class="mb-0 text-muted"
                              >
                                <small>{{ option.phone }}</small>
                              </b-card-text>
                              <b-card-text
                                v-if="option.tipo"
                                class="mb-0 text-muted"
                              >
                                <small>{{ option.tipo }}</small>
                              </b-card-text>
                            </div>
                          </div>
                        </template>
                        <template
                          slot="selected-option"
                          slot-scope="option"
                        >
                          {{ option.desc }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Data da visita')"
                    >
                      <date-picker
                        v-model="form_data.sw070s10"
                        value-type="format"
                        :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                        type="date"
                        :editable="false"
                        :clearable="true"
                        :lang="langCalendar"
                        :append-to-body="true"
                        prefix-class="sw"
                      >
                        <template #icon-clear>
                          <v-select-deselect />
                        </template>
                      </date-picker>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Hora da visita')"
                    >
                      <b-form-timepicker
                        v-model="form_data.sw070s10_time"
                        placeholder="00:00"
                        hide-header
                        now-button
                        :label-close-button="$t('Fechar')"
                        :label-now-button="$t('Hora atual')"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    :label="$t('Observação')"
                  >
                    <b-form-textarea
                      v-model="form_data.sw070s12"
                      placeholder=""
                      rows="3"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

            </validation-observer>
          </b-container>

        </vue-perfect-scrollbar>

        <div
          class="d-flex p-1 justify-content-center d-block"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mr-2"
            @click.stop.prevent="hide"
          >
            {{ $t('Cancelar') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            @click.stop.prevent="saveForm"
          >
            {{ $t('Guardar') }}
          </b-button>
        </div>

        <b-overlay
          :show="processingAjax"
          no-wrap
        />

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, VBTooltip, BFormGroup, BContainer, BFormTimepicker, BFormTextarea, BAvatar, BCardText, BSidebar, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Dialog } from '@capacitor/dialog'
import useCapacitor from '@core-custom/utils/capacitor'
import { sizeIconsAction, debounce } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { mapGetters, mapActions } from 'vuex'
import crmModuleForm from '@store-modules/crm/visitas/form/index'
import { onUnmounted } from '@vue/composition-api'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../listview/mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAvatar,
    BFormGroup,
    BContainer,
    VuePerfectScrollbar,
    BOverlay,
    BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
    vSelectDeselect: vSelect.components.Deselect,
    vSelect,
    DatePicker: oDatepicker,
    BFormTextarea,
    BCardText,
    BSidebar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    openModalAngariacao: {
      type: Function,
      required: false,
      default: null,
    },
    pidListing: {
      type: String,
      required: false,
      default: '',
    },
    changeListing: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      search: [],
      langCalendar: {},
      pidAngariacao: null,
      processingAjax: true,
      listLeadsApp: [],
    }
  },
  computed: {
    ...mapGetters('crm_visitas_form', ['arrayAngariacao', 'form_data', 'arrayConsultoresTeam']),
  },
  async mounted() {
    await this.$store.dispatch('crm_visitas_form/loadArray128').then(async res => {
      if (res === 1) {
        if (this.pidListing !== '') {
          await store.dispatch('crm_visitas_form/getInfoListingByPID', this.pidListing).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      }
    }).catch(error => {
      this.showMsgErrorRequest(error)
    })

    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
    })

    this.processingAjax = false
  },
  methods: {
    ...mapActions('crm_visitas_form', ['setOptionSelectLead']),
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    async showMsgSuccessRequest(info) {
      if (info.message) {
        await this.$swal({
          title: '',
          text: info.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    async setListLeads() {
      await this.$store.dispatch('crm_visitas_form/setListLeads').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async saveForm() {
      this.$root.$emit('app::loading', true)

      await this.$refs.formNovaVisita.validate().then(success => {
        if (success) {
          this.$store.dispatch('crm_visitas_form/saveVisitaForm').then(response => {
            if (response.error !== 1) {
              this.$parent.showFormNewVisit = false
              this.showMsgSuccessRequest({ message: response.msg })
            } else {
              this.showMsgErrorRequest(new Error(response.msg))
            }
            this.$root.$emit('app::loading', false)
          }).catch(error => {
            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequest(error)
          })
        } else {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
        }
      })
    },
    onSearchLeadsAppSelect(search, loading) {
      if (search.length) {
        loading(true)
        this.searchLeadsAppSelect(loading, search, this)
      }
    },
    searchLeadsAppSelect: debounce((loading, search, vm) => {
      const oThis = vm

      vm.$store.dispatch('crm_visitas_form/searchLeadsByUserAuthEncode', search).then(res => {
        oThis.listLeadsApp = res
        loading(false)
      }).catch(error => {
        vm.showMsgErrorRequest(error)
        oThis.listLeadsApp = []
        loading(false)
      })
    }, 500),
  },
  setup() {
    const CRM_VISITAS_FORM_MODULE_NAME = 'crm_visitas_form'

    if (!store.hasModule(CRM_VISITAS_FORM_MODULE_NAME)) {
      store.registerModule(CRM_VISITAS_FORM_MODULE_NAME, crmModuleForm)

      onUnmounted(() => {
        if (store.hasModule(CRM_VISITAS_FORM_MODULE_NAME)) store.unregisterModule(CRM_VISITAS_FORM_MODULE_NAME)
      })
    }

    return {
      avatarText,
      configSettingsScroll,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
