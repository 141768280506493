import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({

  form_data: ref([]),
  // variaveis dos selecionados
  showSimpleFormVisita: false,
  showSimpleFormAngariacaoExternaVisita: false,
  showSimpleFormLeadExternaVisita: false,
  showExternalFormVisita: false,
  showExternalLeadFormVisita: false,
  showModalAngariacao: false,
  addModalAngariacao: false,
  arrayAngariacao: ref([]),
  arraySW128: ref([]),
  arraySW172: ref([]),
  arraySW025: ref([]),
  arraySW175: ref([]),
  arraySW175_index: ref(0),
  arrayTipoNegocio: ref([]),
  arrayTipoTerreno: ref([]),
  arrayTiposImoveisCategoria: ref([]),
  arrayClassesTipologias: ref([]),
  arrayTipologias: ref([]),
  arrayTipologiasExtra: ref([]),
  arrayExtraTipologias: ref([]),
  arrayTiposImoveis: ref([]),
  loadInfoAgencia: false,
  showTipologia: false,
  showTipologiaExtra: false,
  showExtraTipologia: false,
  showTipoTerreno: false,
  flagAngariacaoExternaSaved: false,
  flagAngariacaoExternaAdded: false,
  flagShowAngariacaoExterna: false,
  flagShowConcluir: true,

  array70: ref([
    {
      id: '0',
      desc: i18n.t('Não'),
    },
  ]),

  arrayConsultoresTeam: ref([]),
})

export default defaultState
