import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import checkNested from 'check-nested'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    showSimpleFormVisita: state => state.showSimpleFormVisita,
    showSimpleFormAngariacaoExternaVisita: state => state.showSimpleFormAngariacaoExternaVisita,
    showSimpleFormLeadExternaVisita: state => state.showSimpleFormLeadExternaVisita,
    showExternalFormVisita: state => state.showExternalFormVisita,
    showExternalLeadFormVisita: state => state.showExternalLeadFormVisita,
    showModalAngariacao: state => state.showModalAngariacao,
    addModalAngariacao: state => state.addModalAngariacao,
    pidAngariacao: state => state.pidAngariacao,
    arrayAngariacao: state => state.arrayAngariacao,
    form_data: state => state.form_data.value,
    arraySW128: state => state.arraySW128.value,
    arraySW172: state => state.arraySW172.value,
    arraySW025: state => state.arraySW025.value,
    arraySW175: state => state.arraySW175.value,
    arrayTipoNegocio: state => state.arrayTipoNegocio.value,
    arrayTipoTerreno: state => state.arrayTipoTerreno.value,
    arrayTiposImoveisCategoria: state => state.arrayTiposImoveisCategoria.value,
    arrayClassesTipologias: state => state.arrayClassesTipologias.value,
    arrayTipologiasExtra: state => state.arrayTipologiasExtra.value,
    arrayExtraTipologias: state => state.arrayExtraTipologias.value,
    arrayTiposImoveis: state => state.arrayTiposImoveis.value,
    arraySW175_index: state => state.arraySW175_index.value,
    loadInfoAgencia: state => state.loadInfoAgencia,

    showTipologia: state => state.showTipologia,
    showTipologiaExtra: state => state.showTipologiaExtra,
    showExtraTipologia: state => state.showExtraTipologia,
    showTipoTerreno: state => state.showTipoTerreno,

    flagAngariacaoExternaSaved: state => state.flagAngariacaoExternaSaved,
    flagAngariacaoExternaAdded: state => state.flagAngariacaoExternaAdded,
    flagShowAngariacaoExterna: state => state.flagShowAngariacaoExterna,
    flagShowConcluir: state => state.flagShowConcluir,

    arrayConsultoresTeam: state => state.arrayConsultoresTeam.value,
    array70: state => state.array70.value,

  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setShowSimpleFormVisita(state, payload) {
      state.showSimpleFormVisita = payload
    },
    setShowSimpleFormAngariacaoExternaVisita(state, payload) {
      state.showSimpleFormAngariacaoExternaVisita = payload
    },
    setShowSimpleFormLeadExternaVisita(state, payload) {
      state.showSimpleFormLeadExternaVisita = payload
    },
    setShowModalAngariacao(state, payload) {
      state.showModalAngariacao = payload
      state.addModalAngariacao = payload
    },
    setfSW152_fltConsultor(state, payload) {
      if (payload === null) {
        state.listviewSelectedConsultor.value = ''
        state.listviewSelectedConsultorID.value = ''
      } else {
        state.listviewSelectedConsultor.value = payload.item.desc
        state.listviewSelectedConsultorID.value = payload.item.id
      }
    },
    setLeadSelected(state, payload) {
      if (payload === null) {
        state.form_data.value.sw070s09 = ''
      } else {
        state.form_data.value.sw070s09 = payload
      }
    },
    setArrayAngariacao(state, payload) {
      if (payload === null) {
        state.arrayAngariacao = []
      } else {
        state.arrayAngariacao = payload
      }
    },
    resetFormData(state) {
      state.form_data.value = []
    },
    setArray128(state, payload) {
      if (payload === null) {
        state.arraySW128.value = ''
      } else {
        state.arraySW128.value = payload
      }
    },
    setArray172(state, payload) {
      if (payload === null) {
        state.arraySW172.value = []
      } else {
        state.arraySW172.value = payload
      }
    },
    setArray025(state, payload) {
      if (payload === null) {
        state.arraySW025.value = []
      } else {
        state.arraySW025.value = payload
      }
    },
    setArrayForm(state, payload) {
      if (payload === null) {
        state.form_data.value = []
      } else {
        if (checkNested(payload, 'sw070s02')) { state.form_data.value.sw070s02 = { id: payload.sw070s02, nome: payload.nome_consultor } }
        if (checkNested(payload, 'sw025s01')) { state.form_data.value.sw070s09 = { id: Buffer.from(payload.sw025s01).toString('base64'), desc: payload.nomelead } }
        if (checkNested(payload, 'sw070s10')) { state.form_data.value.sw070s10 = payload.sw070s10.substr(0, 10) }
        if (checkNested(payload, 'sw070s10')) { state.form_data.value.sw070s10_time = payload.sw070s10.substr(11, 15) }
        if (checkNested(payload, 'sw070s12')) { state.form_data.value.sw070s12 = payload.sw070s12 }
        if (checkNested(payload, 'sw070s01')) { state.form_data.value.sw070s01 = payload.sw070s01 }

        if (checkNested(payload, 'sw070s21')) { state.form_data.value.sw070s21 = { sw128s01: payload.sw070s21, sw128s02: payload.txt_sw070s21 } }
        if (checkNested(payload, 'sw070s46')) {
          state.form_data.value.sw070s46 = {
            id_encode: Buffer.from(payload.sw070s46).toString('base64'), contacto: payload.info_sw070s46.contacto, email: payload.info_sw070s46.email, nome: payload.info_sw070s46.nome,
          }
        }
        if (checkNested(payload, 'sw070s26')) { state.form_data.value.sw070s26 = payload.sw070s26 }
        if (checkNested(payload, 'sw070s27')) { state.form_data.value.sw070s27 = payload.sw070s27 }
        if (checkNested(payload, 'sw070s28')) { state.form_data.value.sw070s28 = payload.sw070s28 }
        if (checkNested(payload, 'sw070s29')) { state.form_data.value.sw070s29 = payload.sw070s29 }
        if (checkNested(payload, 'sw070s41')) { state.form_data.value.sw070s41 = { id: payload.sw070s41, designacao_comercial: payload.txt_sw070s41 } }

        state.form_data.value = { ...state.form_data.value }
      }
    },
    addRow025(state, payload) {
      if (state.arraySW025.value === null) {
        state.arraySW025.value = []
      }
      state.arraySW025.value.push({ id_encode: payload.id, nome: payload.desc })
      state.form_data.value.sw070s46 = { id_encode: payload.id, nome: payload.desc }

      state.form_data.value.sw025s03 = ''
      state.form_data.value.sw025s04 = ''
      state.form_data.value.sw025s05 = ''
      state.form_data.value.sw025s06 = ''
    },
    setTipoNegocio(state, payload) {
      if (payload === null) {
        state.arrayTipoNegocio.value = ''
      } else {
        state.arrayTipoNegocio.value = payload
      }
    },
    setTipoTerreno(state, payload) {
      if (payload === null) {
        state.arrayTipoTerreno.value = ''
      } else {
        state.arrayTipoTerreno.value = payload
      }
    },
    setTiposImoveisCategoria(state, payload) {
      if (payload === null) {
        state.arrayTiposImoveisCategoria.value = ''
      } else {
        state.arrayTiposImoveisCategoria.value = payload
      }
    },
    setClassesTipologias(state, payload) {
      if (payload === null) {
        state.arrayClassesTipologias.value = ''
      } else {
        state.arrayClassesTipologias.value = payload
      }
    },
    setTipologias(state, payload) {
      if (payload === null) {
        state.arrayTipologias.value = ''
      } else {
        state.arrayTipologias.value = payload
      }
    },
    setArrayTiposImoveis(state, payload) {
      if (state.form_data.value.sw070s32 !== undefined) {
        state.form_data.value.sw070s32 = null
      }
      if (state.form_data.value.sw070s33 !== undefined) {
        state.form_data.value.sw070s33 = null
      }
      if (state.form_data.value.sw070s34 !== undefined) {
        state.form_data.value.sw070s34 = null
      }
      if (state.form_data.value.sw070s35 !== undefined) {
        state.form_data.value.sw070s35 = null
      }
      if (state.form_data.value.sw070s40 !== undefined) {
        state.form_data.value.sw070s40 = null
      }

      if (payload === null) {
        state.arrayTiposImoveis.value = []
      } else if (Array.isArray(payload)) {
        state.arrayTiposImoveis.value = payload
      } else {
        state.arrayTiposImoveis.value = []
        Object.keys(payload).forEach(index => {
          state.arrayTiposImoveis.value.push(payload[index])
        })
      }
    },
    setArrayTipologiasExtra(state, payload) {
      if (payload === null) {
        state.arrayTipologiasExtra.value = []
      } else if (Array.isArray(payload)) {
        state.arrayTipologiasExtra.value = payload
      } else {
        state.arrayTipologiasExtra.value = []
        Object.keys(payload).forEach(index => {
          state.arrayTipologiasExtra.value.push(payload[index])
        })
      }
    },
    setArrayExtraTipologias(state, payload) {
      if (payload === null) {
        state.arrayExtraTipologias.value = []
      } else if (Array.isArray(payload)) {
        state.arrayExtraTipologias.value = payload
      } else {
        state.arrayExtraTipologias.value = []
        Object.keys(payload).forEach(index => {
          state.arrayExtraTipologias.value.push(payload[index])
        })
      }
    },
    resetNovoAgenteForm(state) {
      state.form_data.value.sw025s03 = ''
      state.form_data.value.sw025s04 = ''
      state.form_data.value.sw025s05 = ''
      state.form_data.value.sw025s06 = ''
    },
    setArrayAgencia128(state, payload) {
      if (state.form_data.value.arrayAgencia128 === null) {
        state.form_data.value.arrayAgencia128 = []
      }

      if (payload !== undefined && payload !== '') {
        state.form_data.value.arrayAgencia128 = payload
      }
    },
    setArrayAgencia172(state, payload) {
      if (state.form_data.value.arrayAgencia172 === null) {
        state.form_data.value.arrayAgencia172 = []
      }

      if (payload !== undefined && payload !== '') {
        // console.log('loadInfoAgencia')
        state.loadInfoAgencia = true
        state.form_data.value.arrayAgencia172 = payload
      }
    },
    setArrayAgencia175(state, payload) {
      if (state.arraySW175.value === null) {
        state.arraySW175.value = []
      }

      if (payload !== undefined && payload !== '') {
        state.arraySW175.value = payload
        state.arraySW175_index.value = payload.length
      }
    },
    addRow175(state) {
      if (state.arraySW175.value === undefined || state.arraySW175.value === null) {
        state.arraySW175.value = []
      }
      state.arraySW175.value.push({ sw175s01: null, sw175s03: null })
    },
    resetArrayAgencia(state) {
      state.loadInfoAgencia = false
      state.form_data.value.arrayAgencia172 = []
      state.form_data.value.arrayAgencia128 = []
      state.arraySW175.value = []
      state.arraySW175_index.value = 0
    },
    resetFullArrayAgencia(state) {
      state.loadInfoAgencia = false
      state.form_data.value.arrayAgencia172 = []
      state.form_data.value.arrayAgencia128 = []
      state.arraySW175.value = []
      state.arraySW175_index.value = 0
      state.form_data.value.sw172s26 = undefined
      state.form_data.value.fSearchRedeAgencia_AMI = null
      state.form_data.value.fSearchRedeAgencia_NIF = null
      state.form_data.value.sw175s03_sel = null
    },
    setRedeImobiliariaSelected(state, payload) {
      state.form_data.value.sw070s21 = payload
    },
    setAgenciaSelected(state, payload) {
      state.form_data.value.sw070s41 = payload
    },
    resetTipologias(state) {
      if (state.form_data.value.sw070s33 !== undefined) {
        state.form_data.value.sw070s33 = null
      }
      if (state.form_data.value.sw070s34 !== undefined) {
        state.form_data.value.sw070s34 = null
      }
      if (state.form_data.value.sw070s35 !== undefined) {
        state.form_data.value.sw070s35 = null
      }
      if (state.form_data.value.sw070s40 !== undefined) {
        state.form_data.value.sw070s40 = null
      }

      if (state.array70.value.length === 1) {
        for (let index = 1; index < 71; index += 1) {
          state.array70.value.push({ id: index, desc: index })
        }
      }
    },
    resetExtraTipologias(state) {
      if (state.form_data.value.sw070s34 !== undefined) {
        state.form_data.value.sw070s34 = null
      }
    },
    resetAngariacaoExterna(state) {
      if (state.form_data.value.sw012s03 !== undefined) {
        state.form_data.value.sw012s03 = null
      }
      if (state.form_data.value.sw070s30 !== undefined) {
        state.form_data.value.sw070s30 = null
      }
      if (state.form_data.value.sw070s31 !== undefined) {
        state.form_data.value.sw070s31 = null
      }
      if (state.form_data.value.sw070s32 !== undefined) {
        state.form_data.value.sw070s32 = null
      }
      if (state.form_data.value.sw070s33 !== undefined) {
        state.form_data.value.sw070s33 = null
      }
      if (state.form_data.value.sw070s34 !== undefined) {
        state.form_data.value.sw070s34 = null
      }
      if (state.form_data.value.sw070s35 !== undefined) {
        state.form_data.value.sw070s35 = null
      }
      if (state.form_data.value.sw070s36 !== undefined) {
        state.form_data.value.sw070s36 = null
      }
      if (state.form_data.value.sw070s37 !== undefined) {
        state.form_data.value.sw070s37 = null
      }
      if (state.form_data.value.sw070s38 !== undefined) {
        state.form_data.value.sw070s38 = null
      }
      if (state.form_data.value.sw070s39 !== undefined) {
        state.form_data.value.sw070s39 = null
      }
      if (state.form_data.value.sw070s40 !== undefined) {
        state.form_data.value.sw070s40 = null
      }
      if (state.form_data.value.sw070s51 !== undefined) {
        state.form_data.value.sw070s51 = null
      }
      if (state.form_data.value.sw070s52 !== undefined) {
        state.form_data.value.sw070s52 = null
      }
    },
    setLoadingImoveis(state, payload) {
      state.loadingImoveis.value = payload
    },
    setLoadingTipologia(state, payload) {
      state.loadingTipologia.value = payload
    },
    setLoadingTipologiaEspecial(state, payload) {
      state.loadingTipologiaEspecial.value = payload
    },
    setShowTipologia(state, payload) {
      state.showTipologia = payload
    },
    setShowTipologiaExtra(state, payload) {
      state.showTipologiaExtra = payload
    },
    setShowExtraTipologia(state, payload) {
      state.showExtraTipologia = payload
    },
    setShowTipoTerreno(state, payload) {
      state.showTipoTerreno = payload
    },
    setFlagAngariacaoExternaSaved(state, payload) {
      state.flagAngariacaoExternaSaved = payload
    },
    setFlagAngariacaoExternaAdded(state, payload) {
      state.flagAngariacaoExternaAdded = payload
    },
    setflagShowAngariacaoExterna(state, payload) {
      state.flagShowAngariacaoExterna = payload
    },
    setArrayConsultoresTeam(state, payload) {
      if (payload === null) {
        state.arrayConsultoresTeam.value = []
      } else if (Array.isArray(payload)) {
        state.arrayConsultoresTeam.value = payload
      } else {
        state.arrayConsultoresTeam.value = []
        Object.keys(payload).forEach(index => {
          state.arrayConsultoresTeam.value.push(payload[index])
        })
      }
    },
    resetListLeads(state) {
      state.form_data.value.sw070s09 = ''
    },
    setFlagShowConcluir(state, payload) {
      state.flagShowConcluir = payload
    },
    setUpdateFormData(state, payload) {
      if (payload === null || payload === '') {
        state.form_data.value = {}
      } else {
        state.form_data.value = { ...payload }
      }
    },
    setUpdateFormDataField(state, payload) {
      try {
        if (payload.field) {
          state.form_data.value[payload.field] = payload.value || ''
        }
      } catch (err) {
        //
      }
    },
  },
  actions: {
    setShowSimpleFormVisita({ commit }, newValue) {
      commit('setShowSimpleFormVisita', newValue)
    },
    setShowSimpleFormAngariacaoExternaVisita({ commit }, newValue) {
      commit('setShowSimpleFormAngariacaoExternaVisita', newValue)
    },
    setShowSimpleFormLeadExternaVisita({ commit }, newValue) {
      commit('setShowSimpleFormLeadExternaVisita', newValue)
    },
    setShowModalAngariacao({ commit }, newValue) {
      commit('setShowModalAngariacao', newValue)
    },
    async saveAngariacao({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw012s02', payload)
        formData.append('sw070', 1)

        // console.log(`flagShowAngariacaoExterna${state.flagShowAngariacaoExterna}`)

        if (state.flagShowAngariacaoExterna === true) {
          formData.append('sw012s379', 1)
        }

        api.post(`${apiConfig.url_base_api}leads/findListingSearchByPID`, formData)
          .then(async response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else if (response.data.list[0].id) {
                  const arrayData = response.data.list[0]
                  commit('setArrayAngariacao', arrayData)

                  // console.log(state.arrayAngariacao)

                  commit('setFlagAngariacaoExternaAdded', true)
                  commit('setFlagAngariacaoExternaSaved', false)
                  commit('setShowTipologia', false)
                  commit('setShowTipologiaExtra', false)
                  commit('setShowExtraTipologia', false)
                  commit('setShowTipoTerreno', false)
                  commit('resetAngariacaoExterna')

                  resolve({ message: i18n.t('Angariação adicionada com sucesso') })
                } else {
                  reject(new Error(i18n.t('Problema ao adicionar a angariação')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao adicionar a angariação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao adicionar a angariação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao adicionar a angariação')))
            }
          })
      })
    },
    setOptionSelectLead({ commit }, newValue) {
      commit('setLeadSelected', newValue)
    },
    async saveVisitaForm({ commit, state }) {
      const formData = new FormData()

      formData.append('sw070s02', (checkNested(state.form_data.value, 'sw070s02') ? Buffer.from(state.form_data.value.sw070s02.id).toString('base64') : ''))
      formData.append('sw070s08', (checkNested(state.arrayAngariacao, 'id') ? state.arrayAngariacao.id : ''))
      formData.append('sw070s09', (checkNested(state.form_data.value, 'sw070s09') ? state.form_data.value.sw070s09.id : ''))
      formData.append('sw070s10', (checkNested(state.form_data.value, 'sw070s10') ? state.form_data.value.sw070s10 : ''))
      formData.append('sw070s10_time', (checkNested(state.form_data.value, 'sw070s10_time') ? state.form_data.value.sw070s10_time.substr(0, 5) : ''))
      formData.append('sw070s12', (checkNested(state.form_data.value, 'sw070s12') ? state.form_data.value.sw070s12 : ''))
      formData.append('sw070s01', (checkNested(state.form_data.value, 'sw070s01') ? Buffer.from(state.form_data.value.sw070s01).toString('base64') : ''))
      formData.append('typeSave', 1)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/visitas/saveVisita`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (Number(response.data.erro) === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  commit('setArrayAngariacao', null)
                  commit('resetFormData')
                  commit('setShowSimpleFormVisita', false)
                  // commit('setFlagShowConcluir', false)

                  resolve(response.data)
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar a informação')))
            }
          })
      })
    },
    resetFormData({ commit }) {
      commit('setArrayAngariacao', null)
      commit('setFlagAngariacaoExternaAdded', false)
      commit('setFlagAngariacaoExternaSaved', false)
      // commit('setFlagShowConcluir', false)
      commit('resetFormData')
    },
    async loadArray128({ commit }, newValue) {
      const formData = new FormData()

      if (newValue !== undefined && newValue !== null) {
        formData.append('bIsExterna', true)
        formData.append('iTipoExterna', newValue)
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/visitas/formNewVisita`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.data.sw128 !== undefined) { commit('setArray128', response.data.data.sw128) }
                if (response.data.data.tipo_negocio !== undefined) { commit('setTipoNegocio', response.data.data.tipo_negocio) }
                if (response.data.data.tipo_terreno !== undefined) { commit('setTipoTerreno', response.data.data.tipo_terreno) }
                if (response.data.data.tiposImoveisCategoria !== undefined) { commit('setTiposImoveisCategoria', response.data.data.tiposImoveisCategoria) }
                if (response.data.data.classes_tipologias !== undefined) { commit('setClassesTipologias', response.data.data.classes_tipologias) }
                if (response.data.data.tipologias !== undefined) { commit('setTipologias', response.data.data.tipologias) }
                if (response.data.data.consultoresTeamCanCreate !== undefined) { commit('setArrayConsultoresTeam', response.data.data.consultoresTeamCanCreate) }

                resolve(1)
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar informação')))
            }
          })
      })
    },
    async loadArray172({ commit, state }, payload) {
      const formData = new FormData()

      if (state.form_data.value.sw070s21 === undefined || state.form_data.value.sw070s21 === null) {
        commit('setArray172', null)
        return 1
      }

      formData.append('sw172s26', Buffer.from(state.form_data.value.sw070s21.sw128s01).toString('base64'))

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}licencasMediacaoImob/getAllByRede`, formData)
          .then(response => {
            try {
              // console.log(response.data)
              if (typeof response.data === 'object') {
                // console.log(response.data.sw172)
                commit('setArray172', response.data.sw172)
                commit('setAgenciaSelected', payload)
                resolve(1)
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar informação')))
            }
          })
      })
    },
    async loadArray025({ commit, state }) {
      const formData = new FormData()

      if (state.form_data.value.sw070s41 === undefined || state.form_data.value.sw070s41 === null) {
        commit('setArray025', null)
        return 1
      }

      formData.append('sw025s129', Buffer.from(state.form_data.value.sw070s41.id).toString('base64'))
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}aliados/getAllAgentes`, formData)
          .then(response => {
            try {
              // console.log(response.data)
              if (typeof response.data === 'object') {
                // console.log(response.data.sw025)
                commit('setArray025', response.data.sw025)
                resolve(1)
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar informação')))
            }
          })
      })
    },
    async saveAgenteForm({ commit, state }) {
      const formData = new FormData()
      // console.log(state.form_data.value.sw070s41)
      formData.append('sw025s128', (checkNested(state.form_data.value, 'sw070s21') ? state.form_data.value.sw070s21.sw128s01 : ''))
      formData.append('sw025s129', (checkNested(state.form_data.value, 'sw070s41') ? Buffer.from(state.form_data.value.sw070s41.id).toString('base64') : ''))
      formData.append('sw025s03', (checkNested(state.form_data.value, 'sw025s03') ? state.form_data.value.sw025s03 : ''))
      formData.append('sw025s04', (checkNested(state.form_data.value, 'sw025s04') ? state.form_data.value.sw025s04 : ''))
      formData.append('sw025s05', (checkNested(state.form_data.value, 'sw025s05') ? state.form_data.value.sw025s05 : ''))
      formData.append('sw025s06', (checkNested(state.form_data.value, 'sw025s06') ? state.form_data.value.sw025s06 : ''))

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/saveFormCreateLeadSimple`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (Number(response.data.error) === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  // console.log('newItem')
                  // console.log(response.data.newItem)
                  commit('addRow025', response.data.newItem)
                  resolve(response.data)
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar a informação')))
            }
          })
      })
    },
    cancelNovoAgente({ commit }) {
      commit('resetNovoAgenteForm')
    },
    cancelNovaAgencia({ commit }) {
      commit('resetFullArrayAgencia')
    },
    async getDataByNIPCAMI({ commit, state }) {
      if ((state.form_data.value.fSearchRedeAgencia_AMI === undefined || state.form_data.value.fSearchRedeAgencia_AMI === null) && (state.form_data.value.fSearchRedeAgencia_NIF === undefined || state.form_data.value.fSearchRedeAgencia_NIF === null)) {
        return { error: 1, msg: i18n.t('Preencha o AMI ou o NIPC') }
      }

      const formData = new FormData()

      if (state.form_data.value.fSearchRedeAgencia_AMI !== undefined && state.form_data.value.fSearchRedeAgencia_AMI !== null) {
        formData.append('ami', state.form_data.value.fSearchRedeAgencia_AMI)
      }

      if (state.form_data.value.fSearchRedeAgencia_NIF !== undefined && state.form_data.value.fSearchRedeAgencia_NIF !== null) {
        formData.append('nif', state.form_data.value.fSearchRedeAgencia_NIF)
      }

      formData.append('key', '')
      formData.append('checkIsSIGNED', 1)

      commit('resetArrayAgencia')

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}modalCreateRedeAgencia/Step3`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  // response.data.sw128
                  if (response.data.sw128 !== undefined) { commit('setArrayAgencia128', response.data.sw128) }

                  // response.data.sw172
                  if (response.data.sw172 !== undefined) { commit('setArrayAgencia172', response.data.sw172) }

                  // response.data.sw175
                  if (response.data.sw175 !== undefined) { commit('setArrayAgencia175', response.data.sw175) }

                  resolve(1)
                }
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar informação')))
            }
          })
      })
    },
    async addNovaAgencia({ commit }) {
      commit('addRow175')
    },
    async resetTipologias({ commit }) {
      commit('setShowTipologia', false)
      commit('setShowTipologiaExtra', false)
      commit('setShowExtraTipologia', false)
      commit('setShowTipoTerreno', false)
      commit('resetTipologias')
    },
    async resetExtraTipologias({ commit }) {
      commit('setShowExtraTipologia', false)
      commit('resetExtraTipologias')
    },
    async saveAgenciaForm({ commit, state }) {
      const formData = new FormData()

      if (state.arraySW175.value.length >= state.arraySW175_index.value) {
        state.arraySW175.value.forEach((value, index) => {
          if (index >= state.arraySW175_index.value && (value.sw175s03 !== undefined && value.sw175s03 !== '' && value.sw175s03 !== null)) {
            formData.append('sw175s03[]', value.sw175s03)
            if ('modeForm' in formData) {
              // em linha
            } else { formData.append('modeForm', 1) }

            if (state.form_data.value.sw175s03_sel === index) {
              formData.append('sw175s03_sel[]', value.sw175s03)
            }
          } else if (state.form_data.value.sw175s03_sel === index) {
            formData.append('sw175s03_sel[]', value.sw175s03)

            if ('modeForm' in formData) {
              // em linha
            } else { formData.append('modeForm', 3) }
          }
        })
      } else if ('modeForm' in formData) {
        // em add
      } else { formData.append('modeForm', 3) }

      if (state.form_data.value.sw172s26 !== undefined && state.form_data.value.sw172s26 !== null) {
        formData.append('sw172s26', Buffer.from(state.form_data.value.sw172s26.sw128s01).toString('base64'))
      }

      formData.append('sw172s01', (checkNested(state.form_data.value.arrayAgencia172, 'sw172s01') ? state.form_data.value.arrayAgencia172.sw172s01 : ''))
      formData.append('showButtonSel', 1)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}modalCreateRedeAgencia/save_Step4`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (Number(response.data.error) === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  if (response.data.sw172s26 && response.data.txt_sw025s128) {
                    commit('setRedeImobiliariaSelected', { sw128s01: Buffer.from(response.data.sw172s26, 'base64').toString('ascii'), sw128s02: response.data.txt_sw025s128 })
                  }
                  let agencia = null
                  if (response.data.sw172s01 && response.data.txt_sw025s129) {
                    agencia = { id: Buffer.from(response.data.sw172s01, 'base64').toString('ascii'), designacao_comercial: response.data.txt_sw025s129 }
                  }
                  resolve({ error: 0, msg: i18n.t('Agências atualizadas com sucesso'), agenciaSelected: agencia })
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar a informação')))
            }
          })
      })
    },
    async saveVisitaFormLeadExterna({ commit, state }) {
      const formData = new FormData()

      formData.append('sw070s02', (checkNested(state.form_data.value, 'sw070s02') ? Buffer.from(state.form_data.value.sw070s02.id).toString('base64') : ''))
      formData.append('sw070s08', (checkNested(state.arrayAngariacao, 'id') ? state.arrayAngariacao.id : ''))
      formData.append('sw070s09', (checkNested(state.form_data.value, 'sw070s09') ? state.form_data.value.sw070s09.id : ''))
      formData.append('sw070s10', (checkNested(state.form_data.value, 'sw070s10') ? state.form_data.value.sw070s10 : ''))
      formData.append('sw070s10_time', (checkNested(state.form_data.value, 'sw070s10_time') ? state.form_data.value.sw070s10_time.substr(0, 5) : ''))
      formData.append('sw070s12', (checkNested(state.form_data.value, 'sw070s12') ? state.form_data.value.sw070s12 : ''))
      formData.append('sw070s01', (checkNested(state.form_data.value, 'sw070s01') ? Buffer.from(state.form_data.value.sw070s01).toString('base64') : ''))
      formData.append('typeSave', 1)

      formData.append('sw070s21', (checkNested(state.form_data.value, 'sw070s21') ? Buffer.from(state.form_data.value.sw070s21.sw128s01).toString('base64') : ''))
      formData.append('sw070s46', (checkNested(state.form_data.value, 'sw070s46') ? state.form_data.value.sw070s46.id_encode : ''))
      formData.append('sw070s26', (checkNested(state.form_data.value, 'sw070s26') ? state.form_data.value.sw070s26 : ''))
      formData.append('sw070s27', (checkNested(state.form_data.value, 'sw070s27') ? state.form_data.value.sw070s27 : ''))
      formData.append('sw070s28', (checkNested(state.form_data.value, 'sw070s28') ? state.form_data.value.sw070s28 : ''))
      formData.append('sw070s29', 1)
      formData.append('sw070s41', (checkNested(state.form_data.value, 'sw070s41') ? Buffer.from(state.form_data.value.sw070s41.id).toString('base64') : ''))

      // console.log(state.form_data.value)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/visitas/saveVisita`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (Number(response.data.erro) === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  commit('setArrayAngariacao', null)
                  commit('resetFormData')
                  commit('setShowSimpleFormLeadExternaVisita', false)

                  resolve(response.data)
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar a informação')))
            }
          })
      })
    },
    async loadListImoveis({ commit, state }) {
      commit('setShowTipologia', false)
      commit('setShowTipologiaExtra', false)
      commit('setShowExtraTipologia', false)
      commit('setShowTipoTerreno', false)

      const formData = new FormData()

      if (state.form_data.value.sw070s31 === undefined || state.form_data.value.sw070s31 === null) {
        commit('setArrayTiposImoveis', null)
        return 1
      }

      formData.append('sw004s08', state.form_data.value.sw070s31.sw087s03)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/getComboTipoImoveisByCategoria`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                commit('setArrayTiposImoveis', response.data.imoveis)
                resolve(1)
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar informação')))
            }
          })
      })
    },
    async loadSubTipologias({ commit, state }) {
      const formData = new FormData()

      if (state.form_data.value.sw070s32 === undefined || state.form_data.value.sw070s32 === null) {
        commit('setArrayTipologiasExtra', null)
        commit('setArrayExtraTipologias', null)
        return 1
      }

      formData.append('sw004s09', state.form_data.value.sw070s32.id)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/getSubTipoImoveis`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.tipos !== undefined && response.data.tipos !== null) {
                  commit('setShowTipologiaExtra', true)
                  commit('setArrayTipologiasExtra', response.data.tipos)
                }

                resolve(1)
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar informação')))
            }
          })
      })
    },
    async loadExtraTipologias({ commit, state }) {
      const formData = new FormData()

      if (state.form_data.value.sw070s32 === undefined || state.form_data.value.sw070s32 === null) {
        commit('setArrayExtraTipologias', null)
        return 1
      }

      formData.append('sw005s09', state.form_data.value.sw070s33.sw005s01)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}tipologias/getExtras`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.tipologias !== undefined && response.data.tipologias !== null) {
                  commit('setShowExtraTipologia', true)
                  commit('setArrayExtraTipologias', response.data.tipologias)
                }

                resolve(1)
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar informação')))
            }
          })
      })
    },
    async resetAngariacaoExterna({ commit }) {
      if (this.flagAngariacaoExternaSaved === false) {
        commit('setShowTipologia', false)
        commit('setShowTipologiaExtra', false)
        commit('setShowTipoTerreno', false)
        commit('resetAngariacaoExterna')
      }
    },
    async setShowTipologia({ commit }, newValue) {
      commit('setShowTipologia', newValue)
    },
    async setShowExtraTipologia({ commit }, newValue) {
      commit('setShowExtraTipologia', newValue)
    },
    async setShowTipologiaExtra({ commit }, newValue) {
      commit('setShowTipologiaExtra', newValue)
    },
    async setShowTipoTerreno({ commit }, newValue) {
      commit('setShowTipoTerreno', newValue)
    },
    async setAngariacaoExternaSaved({ state, commit }) {
      const arrayData = {}
      arrayData.pid = 'Angariação externa'
      if (state.form_data.value.sw070s32 !== undefined) {
        arrayData.tipologia = `${state.form_data.value.sw070s32.desc}`

        if (checkNested(state.form_data.value, 'sw070s35')) {
          arrayData.tipologia += ` (${state.form_data.value.sw070s35[0].sw004s02}`
          state.form_data.value.sw070s35.forEach((o, index) => {
            if (index > 1) {
              arrayData.tipologia += `,${o.sw004s02}`
            }
          })
          arrayData.tipologia += ')'
        }
      }

      if (state.form_data.value.sw070s40 !== undefined) {
        arrayData.tipologia = state.form_data.value.sw070s40.sw008s02
      }
      arrayData.imagem = ''

      commit('setArrayAngariacao', arrayData)
      commit('setFlagAngariacaoExternaAdded', false)
      commit('setFlagAngariacaoExternaSaved', true)
    },
    async saveVisitaFormAngariacaoExterna({ commit, state }) {
      const formData = new FormData()

      formData.append('sw070s02', (checkNested(state.form_data.value, 'sw070s02') ? Buffer.from(state.form_data.value.sw070s02.id).toString('base64') : ''))
      formData.append('sw070s08', (checkNested(state.arrayAngariacao, 'id') ? state.arrayAngariacao.id : ''))
      formData.append('sw070s09', (checkNested(state.form_data.value, 'sw070s09') ? state.form_data.value.sw070s09.id : ''))
      formData.append('sw070s10', (checkNested(state.form_data.value, 'sw070s10') ? state.form_data.value.sw070s10 : ''))
      formData.append('sw070s10_time', (checkNested(state.form_data.value, 'sw070s10_time') ? state.form_data.value.sw070s10_time.substr(0, 5) : ''))
      formData.append('sw070s12', (checkNested(state.form_data.value, 'sw070s12') ? state.form_data.value.sw070s12 : ''))
      formData.append('sw070s01', (checkNested(state.form_data.value, 'sw070s01') ? Buffer.from(state.form_data.value.sw070s01).toString('base64') : ''))
      formData.append('typeSave', 1)

      formData.append('sw070s30', (checkNested(state.form_data.value, 'sw070s30') ? state.form_data.value.sw070s30 : ''))
      formData.append('sw070s31', (checkNested(state.form_data.value, 'sw070s31') ? state.form_data.value.sw070s31.sw087s01 : ''))
      formData.append('sw070s32', (checkNested(state.form_data.value, 'sw070s32') ? state.form_data.value.sw070s32.id : ''))
      formData.append('sw070s33', (checkNested(state.form_data.value, 'sw070s33') ? state.form_data.value.sw070s33.sw005s01 : ''))
      formData.append('sw070s34', (checkNested(state.form_data.value, 'sw070s34') ? state.form_data.value.sw070s34.sw005s01 : ''))

      if (checkNested(state.form_data.value, 'sw070s35')) {
        state.form_data.value.sw070s35.forEach(o => {
          formData.append('sw070s35[]', o.sw004s01)
        })
      }

      formData.append('sw070s36', (checkNested(state.form_data.value, 'sw070s36') ? state.form_data.value.sw070s36.sw007s01 : ''))
      formData.append('sw070s37', (checkNested(state.form_data.value, 'sw070s37') ? state.form_data.value.sw070s37 : ''))
      formData.append('sw070s38', (checkNested(state.form_data.value, 'sw070s38') ? state.form_data.value.sw070s38 : ''))
      formData.append('sw070s39', (checkNested(state.form_data.value, 'sw070s39') ? state.form_data.value.sw070s39 : ''))
      formData.append('sw070s40', (checkNested(state.form_data.value, 'sw070s40') ? state.form_data.value.sw070s40.sw008s01 : ''))
      formData.append('sw070s51', (checkNested(state.form_data.value, 'sw070s51') ? state.form_data.value.sw070s51 : ''))
      formData.append('sw070s52', (checkNested(state.form_data.value, 'sw070s52') ? state.form_data.value.sw070s52 : ''))
      formData.append('sw012s03', (checkNested(state.form_data.value, 'sw012s03') ? state.form_data.value.sw012s03 : ''))

      formData.append('sw070s21', (checkNested(state.form_data.value, 'sw070s21') ? Buffer.from(state.form_data.value.sw070s21.sw128s01).toString('base64') : ''))
      formData.append('sw070s46', (checkNested(state.form_data.value, 'sw070s46') ? state.form_data.value.sw070s46.id_encode : ''))
      formData.append('sw070s29', 2)
      formData.append('sw070s41', (checkNested(state.form_data.value, 'sw070s41') ? Buffer.from(state.form_data.value.sw070s41.id).toString('base64') : ''))

      formData.append('sw070s42', (checkNested(state.form_data.value, 'sw070s42') ? state.form_data.value.sw070s42.id : ''))
      formData.append('sw070s43', (checkNested(state.form_data.value, 'sw070s43') ? state.form_data.value.sw070s43.id : ''))

      // console.log(state.form_data.value)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/visitas/saveVisita`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (Number(response.data.erro) === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  commit('setShowTipologia', false)
                  commit('setShowTipologiaExtra', false)
                  commit('setShowTipoTerreno', false)
                  commit('resetAngariacaoExterna')
                  commit('setArrayAngariacao', null)
                  commit('setFlagAngariacaoExternaSaved', false)
                  commit('setFlagAngariacaoExternaAdded', false)
                  commit('resetFormData')

                  commit('setShowSimpleFormAngariacaoExternaVisita', false)

                  resolve(response.data)
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar a informação')))
            }
          })
      })
    },
    async setflagShowAngariacaoExterna({ commit }, newValue) {
      commit('setflagShowAngariacaoExterna', newValue)
    },
    async searchLeadsByUserAuthEncode({ state, dispatch }, payload) {
      const formData = new FormData()
      formData.append('q', payload)

      if (state.arrayConsultoresTeam.value.length > 0) {
        formData.append('userTeam', (checkNested(state.form_data.value, 'sw070s02') ? Buffer.from(state.form_data.value.sw070s02.id).toString('base64') : 0))
      }

      await dispatch('request/cancelTokensPendingByUrl', 'leads/visitas/searchLeadsByUserAuthEncode', { root: true })

      return new Promise(resolve => {
        api.post(`${apiConfig.url_base_api}leads/visitas/searchLeadsByUserAuthEncode`, formData)
          .then(response => {
            if (typeof response.data.list === 'object') {
              resolve(response.data.list)
            } else {
              resolve([])
            }
          })
      })
    },
    async setListLeads({ commit }) {
      commit('resetListLeads')
    },
    async getInfoListingByPID({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw012s02', payload)
        formData.append('sw070', 1)

        api.post(`${apiConfig.url_base_api}leads/findListingSearchByPID`, formData)
          .then(async response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else if (response.data.list[0].id) {
                  const arrayData = response.data.list[0]
                  commit('setArrayAngariacao', arrayData)

                  resolve(true)
                } else {
                  reject(new Error(i18n.t('Problema ao carregar informação da angariação')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao carregar informação da angariação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar informação da angariação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar informação da angariação')))
            }
          })
      })
    },
    async setFlagShowConcluir({ commit }, newValue) {
      commit('setFlagShowConcluir', newValue)
    },
    async getDataVisita({ commit, dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/visitas/formNewVisita', { root: true })

      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('visita', payload.sw070s01 || '')
        formData.append('mode', 'edit')
        formData.append('bIsExterna', payload.externa)

        api.post(`${apiConfig.url_base_api}leads/visitas/formNewVisita`, formData)
          .then(response => {
            try {
              if (response.data.data.formInfoListingSel !== null) {
                commit('setArrayAngariacao', response.data.data.formInfoListingSel)
                commit('setFlagAngariacaoExternaAdded', true)
              }

              if (response.data.data.sw070 !== null) {
                commit('setArrayForm', response.data.data.sw070)
              }

              if (response.data.data.sw128 !== null) {
                commit('setArray128', response.data.data.sw128)
              }

              if (response.data.data.sw172 !== null) {
                commit('setArray172', response.data.data.sw172)
              }

              if (response.data.data.sw025_Agentes !== null) {
                commit('setArray025', response.data.data.sw025_Agentes)
              }

              if (response.data.data.consultoresTeamCanCreate !== undefined && response.data.data.consultoresTeamCanCreate !== null) {
                commit('setArrayConsultoresTeam', response.data.data.consultoresTeamCanCreate)
              }

              resolve(1)
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o filtro')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o filtro')))
            }
          })
      })
    },

    updateFieldValue({ state, commit }, payload) {
      if (Array.isArray(payload)) {
        payload.forEach(field => {
          commit('setUpdateFormDataField', field)
        })
      } else {
        commit('setUpdateFormDataField', payload)
      }
      commit('setUpdateFormData', state.form_data.value)
    },
  },
}
